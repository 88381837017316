import {
    BankAccountType,
    EntityModelLoanViewDTOStatusEnum,
    FeeRepaymentMethod,
    LoanApplicationStatus,
    StartPage,
} from 'src/generated-api-client';

export const periodMapping = {
    THIRTY: 30,
    SIXTY: 60,
    NINETY: 90,
};

export const LoanApplicationMapping = {
    [LoanApplicationStatus.CREDITBUREAUCHECKING]: 'CB checking',
    [LoanApplicationStatus.INITIALWAITINGDOCUMENTS]: 'Waiting documents',
    [LoanApplicationStatus.INITIALREVIEW]: 'Initial review',
    [LoanApplicationStatus.INFOREQUESTED]: 'Info requested',
    [LoanApplicationStatus.CREDITCOMMITTEEREVIEW]: 'CC review',
    [LoanApplicationStatus.OFFERISSUED]: 'Offer issued',
    [LoanApplicationStatus.SIGNINGPROCESS]: 'Signing process',
    [LoanApplicationStatus.SIGNED]: 'Signed',
    [LoanApplicationStatus.EXPIRED]: 'Expired',
    [LoanApplicationStatus.DECLINEDBYREVIEWER]: 'Declined',
    [LoanApplicationStatus.DECLINEDBYCREDITBUREAU]: 'Declined by CB',
    [LoanApplicationStatus.DECLINEDBYCREDITCOMMITTEE]:
        'Rejected by credit committee',
    [LoanApplicationStatus.DECLINEDBYCUSTOMER]: 'Refused',
    [LoanApplicationStatus.FINALOFFERISSUED]: 'Final offer issued',
};

export const applicationStatusMapColors = {
    [LoanApplicationStatus.INITIALWAITINGDOCUMENTS]: '#FFFF80',
    [LoanApplicationStatus.CREDITBUREAUCHECKING]: '#FFFF80',
    [LoanApplicationStatus.INITIALREVIEW]: '#FFFF80',
    [LoanApplicationStatus.INFOREQUESTED]: '#FFFF80',
    [LoanApplicationStatus.CREDITCOMMITTEEREVIEW]: '#FFFF80',
    [LoanApplicationStatus.OFFERISSUED]: '#D6F7A0',
    [LoanApplicationStatus.SIGNINGPROCESS]: '#D6F7A0',
    [LoanApplicationStatus.SIGNED]: '#D6F7A0',
    [LoanApplicationStatus.FINALOFFERISSUED]: '#D6F7A0',
    [LoanApplicationStatus.EXPIRED]: '#FFFF80',
    [LoanApplicationStatus.DECLINEDBYREVIEWER]: '#EB7382',
    [LoanApplicationStatus.DECLINEDBYCREDITBUREAU]: '#EB7382',
    [LoanApplicationStatus.DECLINEDBYCREDITCOMMITTEE]: '#EB7382',
    [LoanApplicationStatus.DECLINEDBYCUSTOMER]: '#EB7382',
};

export const customerMapColors = {
    WAIT_DOCUMENTS: '#FFFF4A',
    IN_REVIEW: '#FFFF80',
    VERIFIED: '#ACF392',
    DECLINED: '#EB7382',
    CREDIT_BUREAU_CHECK: '#FFFAE6',
    REJECTED_BY_CREDIT_BUREAU: '#EB7382',
    INFO_REQUESTED: '#FFFAE6',
};

export const loanMapColors = {
    [EntityModelLoanViewDTOStatusEnum.ACTIVE]: '#ACF392',
    [EntityModelLoanViewDTOStatusEnum.OVERDUE]: '#EB7382',
    [EntityModelLoanViewDTOStatusEnum.WRITTENOFF]: '#EB7382',
    [EntityModelLoanViewDTOStatusEnum.DEFAULT]: '#EB7382',
    [EntityModelLoanViewDTOStatusEnum.PAIDUP]: '#ACF392',
};

export const feeRepaymentMethodMapping = {
    [FeeRepaymentMethod.ADDTOLOAN]: 'Add to the total cost of credit',
    [FeeRepaymentMethod.UPFRONTPAYMENT]: 'Pay it before the loan disbursement',
    [FeeRepaymentMethod.DEDUCTFROMDISBURSEMENT]:
        'Deduct from the disbursement amount',
};

export const bankAccountType = {
    [BankAccountType.CUSTOMER]: 'Customer',
    [BankAccountType.DISBURSEMENT]: 'Disbursment',
    [BankAccountType.THIRDPARTY]: 'Third party',
};

export const START_PAGES_OPTIONS = [
    {
        children: 'Loans',
        value: StartPage.LOANS,
    },
    {
        children: 'Application',
        value: StartPage.APPLICATIONS,
    },
    {
        children: 'Customer',
        value: StartPage.CUSTOMERS,
    },
    {
        children: 'Loan product',
        value: StartPage.LOANPRODUCTS,
    },
    {
        children: 'Users',
        value: StartPage.USERS,
    },
    {
        children: 'Roles',
        value: StartPage.ROLES,
    },
    {
        children: 'Notifications',
        value: StartPage.NOTIFICATIONS,
    },
    {
        children: 'Payments',
        value: StartPage.PAYMENTS,
    },
];
