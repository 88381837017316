import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { FilesListComponent } from 'src/components/FilesListComponent/FilesListComponent';
import { Spinner } from 'src/components/Spinner/Spinner';
import { DocumentDTO, LoanApplicationType } from 'src/generated-api-client';
import { useApplicationId } from 'src/hooks/useApplicationId';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { CustomerDocumnetsStore } from 'src/stores/CustomerDocumnetsStore/CustomerDocumnetsStore';
import styled from 'styled-components';

const store = ApplicationsStore;
const customerDocumentsStore = CustomerDocumnetsStore;

export const ApplicationDocuments = observer(() => {
    const { applicationId } = useApplicationId();
    const { t } = useTranslation();

    const documentsList = [
        {
            title: t(
                'Document.DTODocument.Type.LOAN_APPLICATION_BANK_STATEMENT',
            ),
            store,
            files: store.applicationBankStatement || [],
        },
        {
            title: t('Document.DTODocument.Type.LOAN_APPLICATION_TAX_INVOICE'),
            store,
            files: store.applicationTaxInvoice || [],
        },
        {
            title: t(
                'Document.DTODocument.Type.LOAN_APPLICATION_MAIN_CONTRACT',
            ),
            store,
            files: store.applicationContract || [],
        },
        {
            title: t(
                'Document.DTODocument.Type.LOAN_APPLICATION_ACCOUNTS_AGING',
            ),
            store,
            files: store.customerAging || [],
        },
        {
            title: t('Document.DTODocument.Type.SIGNED_AGREEMENT'),
            store,
            files: store.applicationSignedAgreement || [],
        },
        {
            title: t(
                'Document.DTODocument.Type.LOAN_APPLICATION_DEBTORS_CONTRACT',
            ),
            store,
            files: store.applicationDebtorsContract || [],
        },
        {
            title: (
                <Trans i18nKey="Document.DTODocument.Type.LOAN_APPLICATION_FINANCIAL_STATEMENT" />
            ),
            store,
            files: store.applicationFinancialStatement || [],
        },
        {
            title: t(
                'Document.DTODocument.Type.LOAN_APPLICATION_PURCHASE_ORDER',
            ),
            store,
            files: store.applicationPurcheseOrder || [],
        },
        {
            title: t(
                'Document.DTODocument.Type.LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT',
            ),
            store,
            files: store.applicationPurcheseOrderContract || [],
            defaultValue:
                store.currentItem?.data?.type ===
                LoanApplicationType.PURCHASEORDERFINANCE,
        },
        {
            title: t(
                'Document.DTODocument.Type.LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET',
            ),
            store,
            files: store.applicationProfitAndLossBudget || [],
        },
        {
            title: t(
                'Document.DTODocument.Type.LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER',
            ),
            store,
            files: store.applicationThirdPartyProofLetter || [],
        },
        {
            title: t('Document.DTODocument.Type.CREDIT_BUREAU_REPORT'),
            store: customerDocumentsStore,
            files: customerDocumentsStore.customerCBDocs || [],
        },

        {
            title: t('Document.DTODocument.Type.Quotation'),
            store,
            files: store.customerQuotationDocs || [],
        },
    ];

    useEffect(() => {
        store.loadDocuments(Number(applicationId));
    }, [applicationId]);

    useEffect(() => {
        customerDocumentsStore.loadList(
            Number(store.currentItem?.data?.customerId) as number,
        );
    }, [store.currentItem?.data?.customerId]);

    return store.applicationDocuments.isLoading ? (
        <StyledSpinnerContainer>
            <Spinner />{' '}
        </StyledSpinnerContainer>
    ) : (
        <Row gutter={[24, 24]}>
            {documentsList.map((file) => {
                if (file?.files?.length > 0) {
                    return (
                        <Col span={12}>
                            <DataView
                                value={
                                    <FilesListComponent
                                        filesList={file.files as DocumentDTO[]}
                                        store={file.store}
                                    />
                                }
                                label={file.title}
                            />
                        </Col>
                    );
                }

                if (file?.defaultValue) {
                    return (
                        <Col span={12}>
                            <DataView value={undefined} label={file?.title} />{' '}
                        </Col>
                    );
                }

                return null;
            })}
        </Row>
    );
});

const StyledSpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
